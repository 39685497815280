































import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";

@Component
export default class EpisodeDetail extends Vue {
  public goal = {
    id: 0
  };
  public settings: Settings;
  public id: number;
  public widgetHost: string;

  public constructor() {
    super();
    this.settings = new Settings();
    this.id = +this.$route.params.id;
    this.widgetHost = this.settings.settings.paymentWidgetHost;
  }

  public loadData(): void {
    this.axios.get(this.settings.settings.host + '/api/goals/' + this.$route.params.id, { //this.id.toString(),{
      params: {},
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.goal = response.data;
    });
  }

  created(): void {
    this.loadData();
  }
}
